import { ChargeOnCompanyMappingConstant } from "presentation/constant/ChargeOnCompanyMapping/ChargeOnCompanyMappingConstant";
import { useChargeOnCompanyMappingVM } from "presentation/hook/ChargeOnCompanyMapping/useChargeOnCompanyMappingVM";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core/IconButtons/IconButtons";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ChargeOnCompanyMappingTitleBar:React.FC = () => {
    const CHARGE_ON_COMPANY_MAPPING_CONSTANT= ChargeOnCompanyMappingConstant.Title;
    // const messageBarVM = useMessageBarVM();  
    // const [isLoading, setIsLoading] = useState(false);
    // const [chargeOnCompanyMappingState] = useChargeOnCompanyMappingTracked();
    // const {updatedRows} = chargeOnCompanyMappingState;
    const chargeOnCompanyMappingVM = useChargeOnCompanyMappingVM();
    

    const handleAdd = useCallback(() => {
        chargeOnCompanyMappingVM.onAddClick();
    }, [chargeOnCompanyMappingVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await chargeOnCompanyMappingVM.onApply(updatedRows);
    //     setIsLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await chargeOnCompanyMappingVM.searchAllChargeOnCompanyMappingList();
    //     }
    //     setIsLoading(false);
    // }, [chargeOnCompanyMappingVM, messageBarVM, updatedRows]);

    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     chargeOnCompanyMappingVM.searchAllChargeOnCompanyMappingList().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[chargeOnCompanyMappingVM]);

    // const isApplyDisable = () => {
    //     if(updatedRows.length !== 0) return false
    //     return true;
    // }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{CHARGE_ON_COMPANY_MAPPING_CONSTANT.TITLE}</HeaderTitle>
        {/* {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> } */}
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/>
            <div className="add-seperator"/> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            {/* <div className="add-seperator"/> */}
            {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(ChargeOnCompanyMappingTitleBar);