import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ChargeOnCompanyMappingConstant } from "./ChargeOnCompanyMappingConstant";
const COMPANY_MAPPING_CONSTANT = ChargeOnCompanyMappingConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_CHARGE_ON_COMPANY_MAPPING_COL_DEF: any[] = [
    {
        headerName: COMPANY_MAPPING_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SOA,
        field: "soa",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 100,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SOAARRIVAL,
        field: "arrivalSOA",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SOADEPARTURE,
        field: "departureSOA",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.OPS_LINE,
        field: "owner",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.FORWARDER_CODE,
        field: "forwarderCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.FORWARDER_REF,
        field: "forwarderReference",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.VESSEL_TYPE,
        field: "vesselType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.CHARGE_CODE,
        field: "chargeCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.OPERATING_TML,
        field: "operatingTml",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 160,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.CONSORTIUM,
        field: "consortiumCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SERVICE,
        field: "serviceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.CONTAINER_STATUS,
        field: "cntrStatus",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.CHARGEON_COMPANY,
        field: "chargeOnCompanyCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SERVICE_ARRIVAL,
        field: "arrivalServiceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SERVICE_DEPARTURE,
        field: "departureServiceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL,
        field: "modalityOfArrival",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE,
        field: "modalityOfDeparture",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 220,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.VESSEL_CODEARRIVAL,
        field: "arrivalVesselCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.VESSEL_CODEDEPARTURE,
        field: "departureVesselCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 230,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.ALTERABLE_CHARGEON_COMPANY,
        field: "alterableChargeOnCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 280,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.MARSHALLING_TYPE,
        field: "marshallingType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.MARSHAL_FROM,
        field: "marshalFrom",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.MARSHAL_TO,
        field: "marshalTo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND,
        field: "specialHandlingInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 220,
    },
    {
        headerName: COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1,
        field: "specialHandlingIndD1",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 240,
    },
            
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
