export const ChargeOnCompanyMappingConstant = {
    Table:{
        PRIORITY: "Priority",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        SOA: "SOA",
        SOAARRIVAL: "SOA(Arrival)",
        SOADEPARTURE: "SOA(Departure)",
        OPS_LINE: "Ops Line",
        FORWARDER_CODE: "Forwarder Code",
        FORWARDER_REF: "Forwarder Ref",
        VESSEL_TYPE: "Vessel Type",
        CHARGE_CODE: "Charge Code",
        OPERATING_TML: "Operating Tml",
        CONSORTIUM: "Consortium",
        SERVICE: "Service",
        CONTAINER_STATUS: "Container Status",
        CHARGEON_COMPANY: "Charge-on Company",
        SERVICE_ARRIVAL: "Service (Arrival)",
        SERVICE_DEPARTURE: "Service (Departure)",
        MODALITY_OF_ARRIVAL: "Modality Of Arrival",
        MODALITY_OF_DEPARTURE: "Modality Of Departure",
        VESSEL_CODEARRIVAL: "Vessel Code(Arrival)",
        VESSEL_CODEDEPARTURE: "Vessel Code(Departure)",
        ALTERABLE_CHARGEON_COMPANY: "Alterable Charge-on Company",
        ACTIVE_IND: "Active Ind.",
        MARSHALLING_TYPE: "Marshalling Type",
        MARSHAL_FROM: "Marshal From",
        MARSHAL_TO: "Marshal To",
        SPECIAL_HANDLING_IND: "Special Handling Ind.",
        SPECIAL_HANDLING_IND_D1: "Special Handling Ind.(D1)",
    },
    Title:{
        TITLE: "Charge On Company Setting",
        MAPPING_FROM: "From",
        MAPPING_TO: "To",
    }
}
export const chargeOnCompanyMappingRequiredFieldList: string[] = [
    'chargeOnCompanyCode',
    'activeInd',
];