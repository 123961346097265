import { useChargeOnCompanyMappingVM } from "presentation/hook/ChargeOnCompanyMapping/useChargeOnCompanyMappingVM";
import { useChargeOnCompanyMappingTracked } from "presentation/store/ChargeOnCompanyMapping/ChargeOnCompanyMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeOnCompanyMappingTitleBar from "./ChargeOnCompanyMappingTitleBar";
import ChargeOnCompanyMappingEditPanel from "./RightPanel/ChargeOnCompanyMappingEditPanel";
import ChargeOnCompanyMappingTablePanel from "./Table/ChargeOnCompanyMappingTablePanel";


const ChargeOnCompanyMappingMaintenance:React.FC = () => {
    const [chargeOnCompanyMappingState] = useChargeOnCompanyMappingTracked();
    const {isShowEditPanel} = chargeOnCompanyMappingState;
    const chargeOnCompanyMappingVM = useChargeOnCompanyMappingVM();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const initialScreen = async() => {   
            try {     
                const results = await Promise.allSettled([
                    chargeOnCompanyMappingVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {            
            chargeOnCompanyMappingVM.searchAllChargeOnCompanyMappingList().then((data) =>{
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [chargeOnCompanyMappingVM])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
        
        {!(isShowEditPanel)&&
        <><ChargeOnCompanyMappingTitleBar/>
        <ChargeOnCompanyMappingTablePanel/></>}
        {(isShowEditPanel)&&
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"100%"}
                rightSectionWidth={"0%"}
                leftChildren={<ChargeOnCompanyMappingEditPanel/>}
                />}
        </div>
    </>
}

export default memo(ChargeOnCompanyMappingMaintenance);